<script>

import FveSelectAjaxV2Mixin from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Mixin";

export default {
  mixins: [
    FveSelectAjaxV2Mixin
  ],
  data() {
    return {
      multiple: true
    };
  },
  props: {
    // значение по умолчанию (можно переопределить тип)
    value    : { type: Array, default: () => [] },
  },
  methods: {
    getItemId(item) {
      return item.id;
    },
    getItemName(item) {
      return item.name;
    },
    request(query) {
      return RequestManager.Club.getClubList({limit:20, search: query}).then((response) => {
        return response.results;
      });
    },
  },
  computed: {
    _value: {
      get() {
        return this.value.map( (item) => {
          return {
            id: this.getItemId(item) ,
            name: this.getItemName(item),
            origin: item,
          };
        });
      },
      set(value) {
        let returnValue = value.map( (item) => {
          return item.origin;
        });
        this.inputFormElement(returnValue);
      }
    }
  },
  mounted() {
    this.asyncFind('', -1);
  }
};
</script>
