<template>
  <form class="form">

    <FveBlockSwitchActive
        v-if="editForm"
        v-model="form.is_active"
        class="fix-swich"
    />

    <fieldset class="form__fieldset">
      <legend class="form__legend">Вход в систему</legend>
      <div class="row">
        <div class="col-4">
          <FveLogin
            label="Логин"
            name="login"
            required
            v-model="form.username"
          />
        </div>
        <div class="col-4">
          <FveEmail
            label="E-mail"
            name="email"
            v-model="form.email"
          />
        </div>
      </div>
    </fieldset>
    <fieldset class="form__fieldset">
      <legend class="form__legend">Личная информация</legend>

      <div class="mb-md">
        <div class="row">
          <div class="col-3">
            <FveFileImageCropperPreview
              label=""
              name="avatar"
              v-model="form.avatar"
            />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-4">
                <FveText
                  label="Фамилия"
                  name="last_name"
                  required
                  v-model="form.last_name"
                />
              </div>
              <div class="col-4">
                <FveText
                  label="Имя"
                  name="first_name"
                  required
                  v-model="form.first_name"
                />
              </div>
              <div class="col-4">
                <FveText
                  label="Отчество"
                  name="middle_name"
                  v-model="form.middle_name"
                />
              </div>
              <div class="col-12">
                <span class="form__label">Доступ</span>
                <b-form-group>
                  <b-form-radio-group
                    v-model="form.access"
                    :options="[
                      { text: 'Клубы' , value: 'club'   },
                      { text: 'Система'  , value: 'system'  },
                    ]"
                    name="radios-btn"
                    buttons
                    button-variant="primary"
                  ></b-form-radio-group>
                </b-form-group>
              </div>

              <div class="col-12" v-if=" form.access === 'club' ">
                <FveSelectAjaxClubMultiple
                  label="Клубы"
                  name="club"
                  v-model="form.clubList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </fieldset>
  </form>
</template>

<script>

import FveText              from "@widgetFormValidate/src/Element/Text/FveText";
import FveEmail             from "@widgetFormValidate/src/Element/Text/FveEmail";
import FveLogin             from "@widgetFormValidate/src/Element/Text/FveLogin";
import FveFileImageCropperPreview from "@widgetFormValidate/src/Element/File/FveFileImageCropperPreview";

//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";
import FveSelectAjaxClubMultiple from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxClubMultiple";

export default {
  mixins: [
    FveFormMixin
  ],
  props: {
    editForm: {type: Boolean, default: false}
  },
  components: {
    FveSelectAjaxClubMultiple,
    FveBlockSwitchActive,
    FveText,
    FveEmail,
    FveLogin,
    FveFileImageCropperPreview,
  },
  data(){
    return {};
  },
  methods: {
    formSchema() {
      return {
        //
        is_active    : { type: Boolean, default: () => { return true; } },
        //
        mobile       : { type: String, default: () => { return ''; } },
        username     : { type: String, default: () => { return ''; } },
        email        : { type: String, default: () => { return ''; } },
        //
        last_name    : { type: String, default: () => { return ''; } },
        first_name   : { type: String, default: () => { return ''; } },
        middle_name  : { type: String, default: () => { return ''; } },
        //
        about        : { type: String, default: () => { return ''; } },
        //
        avatar       : { type: FileClass, default: () => { return null; } },
        //
        access       : { type: String, default: () => { return 'club'; } },
        clubList     : { type: Array , default: () => { return []; } },
      };
    },
  },
  mounted() {
  }
};

</script>

<style lang="scss" scoped>

</style>
